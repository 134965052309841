import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import ContactForm from '../contact/form'
import { RichText } from '../html'
import Title from './Title'
import PhoneIcon from '../../../static/images/phone.svg'
import LocationIcon from '../../../static/images/location.svg'
import MailIcon from '../../../static/images/envelope.svg'
import './ContactSection.scss'

const ContactSection = ({ body }) => {
  const { primary } = body
  const { contact_data: contactData } = primary
  if (!contactData) { return null }
  const { document: { data } } = contactData
  const {
    title,
    description,
    address1,
    address2,
    email,
    phone,
    productOptions,
    ...rest
  } = data
  return (
    <div>
      <div className='contact-section'>
        <div className='contact-info'>
          <Title title={title} />
          <RichText render={description} />
          <div className='contact-details-container'>
            {address1 && (
              <div className='contact-detail'>
                <LocationIcon /><small>{address1}</small>
              </div>
            )}
            {address2 && (
              <div className='contact-detail'>
                <LocationIcon /><small>{address2}</small>
              </div>)}
            {email && (
              <div className='contact-detail'>
                <a href={`mailto:${email}`}>
                  <MailIcon /><small>{email}</small>
                </a>
              </div>)
            }
            {phone && (
              <div className='contact-detail'>
                <a href={`tel:${phone.replace(/\s/g, '')}`}>
                  <PhoneIcon /><small>{phone}</small>
                </a>
              </div>
            )}
          </div>
        </div>
        <ContactForm productOptions={productOptions && productOptions.map((prod) => prod.product_name)} {...rest} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ContactSectionFragment on PrismicPageBodyContactSection {
    slice_type
    primary {
      contact_data {
        document {
          ...on PrismicContact {
            data {
              title {
                html
              }
              description {
                html
              }
              address1: address_1
              address2: address_2
              phone
              email
              productOptions: product_options {
                product_name
              }
              labelName: label_name
              labelEmail: label_email
              labelPhone: label_phone
              labelProductOfInterest: label_product_of_interest
              labelMessage: label_message
              sendLabel: send_label
              successMessage: success_message
            }
          }
        }
      }
    }
  }
`

ContactSection.propTypes = {
  body: PropTypes.object.isRequired,
}

export default ContactSection
