import React from 'react'
import PropTypes from 'prop-types'

import { RichText } from '../html'
import './Title.scss'

const Title = ({ title, centered }) => (
  <div className={`marine-title ${centered && 'marine-title-centered'}`}>
    <RichText render={title} />
  </div>
)

Title.propTypes = {
  title: PropTypes.object,
  centered: PropTypes.bool,
}

export default Title
