import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { RichText, isEmptyText } from '../html'
import Slider from '../slider'
import Title from './Title'
import './ProjectsSlider.scss'

const ProjectsSlider = ({ body }) => {
  const { primary, items } = body
  const {
    size_items_mobile: sizeItemsMobile,
    size_items_tablet: sizeItemsTablet,
    size_items_desktop: sizeItemsDesktop,
    // if small > we show image to the left and text to the right, if big > image is full width and text underneath
    image_size: imageSize,
  } = primary
  /* For now if only 1 item is beign shown in desktop version we create a bigger layout for the slide item */
  /* I do < 2 because in prismic you can set negative values. */
  const isFullLayout = sizeItemsDesktop < 2

  let sliderItemClass = isFullLayout ? 'slider-full-item-wrapper' : 'slider-item-wrapper'
  if (imageSize === 'big') {
    sliderItemClass = 'slider-full-item-wrapper slider-full-image'
  }
  return (
    <div id={primary.id}>
      <div className='header-section'>
        <div>
          <Title title={primary.sliderTitle} />
          <RichText render={primary.subtitle} />
        </div>
        <div>
          <RichText render={primary.description} />
        </div>
      </div>
      <Slider
        fullLayout={isFullLayout}
        slidesToShow={{ mobile: sizeItemsMobile, tablet: sizeItemsTablet, desktop: sizeItemsDesktop }}
      >
        {items.map(({ image, title, description }) => (
          <div className={sliderItemClass}>
            <GatsbyImage fluid={image.fluid} alt={image.alt} />
            {/*  Only use alt image as pic label when no title and no description are provided */}
            {isEmptyText(title) && isEmptyText(description) ? <p>{image.alt}</p> : null}
            {/*  Otherwise we use title and/or description */}
            {isFullLayout && (
              <div className='item-text-content'>
                {!isEmptyText(title) && <RichText render={title} />}
                {!isEmptyText(description) && <div className='item-text-description'><RichText render={description} /></div>}
              </div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export const query = graphql`
  fragment ProjectsSliderFragment on PrismicPageBodyProjectsSlider {
    slice_type
    primary {
      id
      sliderTitle: title {
        html
      }
      subtitle: subtitle {
        html
      }
      description: description {
        html
      }
      size_items_mobile
      size_items_tablet
      size_items_desktop
      image_size
    }
    items {
      image {
        alt
        fluid {
          sizes
          src
          aspectRatio
          base64
          srcWebp
          srcSetWebp
        }
      }
      title {
        html
        text
      }
      description {
        html
        text
      }
    }
  }
`

ProjectsSlider.propTypes = {
  body: PropTypes.object.isRequired,
}

export default ProjectsSlider
