import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import RichText from '../html/RichText'
import Media from '../media'
import Button from '../button'
import './Hero.scss'

const Hero = ({ data }) => {
  const mediaDoc = data.media.document
  return (
    <div className='max-width-container hero-container'>
      <div className='hero-left-column'>
        <RichText render={data.title} />
        <div className='hero-image show-mobile'>
          {mediaDoc && <Media id={mediaDoc.id} data={mediaDoc.data} />}
        </div>
        <div className='hero-description'><RichText render={data.description} /></div>
        <div className='hero-button'>
          {data.contact_button_text && <Button to='/contact'>{data.contact_button_text.text}</Button>}
        </div>
      </div>
      <div className='hero-image show-desktop'>
        {mediaDoc && <Media id={mediaDoc.id} data={mediaDoc.data} />}
      </div>
    </div>
  )
}


Hero.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  fragment HeroFragment on PrismicPageBodyHero {
    slice_type
    primary {
      title {
        html
      }
      description {
        html
      }
      contact_button_text {
        text
      }
      media {
        ...PrismicMediaFragment
      }
    }
  }
`

export default Hero
