import React from 'react'
import PropTypes from 'prop-types'

import Button from './index'

const ButtonBar = ({ buttonArray }) => {
  if (buttonArray) {
    return (
      <div className='button-bar'>{
        buttonArray.map((buttonData) => (
          <Button to={buttonData.url} secondary={buttonData.style === 'outlined'}>
            {buttonData.label}
          </Button>))}
      </div>)
  }
  return null
}

ButtonBar.propTypes = {
  buttonArray: PropTypes.array,
}

export default ButtonBar
