import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { RichText, isEmptyText } from '../html'
import Title from './Title'
import Media from '../media'
import ButtonBar from '../button/ButtonBar'
import './ServiceSummary.scss'

const ServiceSummary = ({ body }) => {
  const { primary, items } = body
  const { id, buttons, invert_layout: invertLayout } = primary

  let buttonArray
  if (buttons && buttons.document) {
    const { button: _buttonArray } = buttons.document.data
    buttonArray = _buttonArray
  }

  return (
    <div
      id={id}
      className='max-width-container service-summary-wrapper full-padding'
      style={{ flexDirection: invertLayout ? 'row-reverse' : 'row' }}
    >
      <div className='service-text-content'>
        <Title title={primary.serviceTitle} />
        <RichText render={primary.description} />
        {!isEmptyText(primary.serviceTitle2) && (
          <Title title={primary.serviceTitle2} />
        )}
        {!isEmptyText(primary.description2) && (
          <RichText render={primary.description2} />
        )}
        <ButtonBar buttonArray={buttonArray} />
      </div>
      {items && (
        <div className={`service-media ${invertLayout ? 'left-media' : 'right-media'}`}>
          {items.map((item) => (
            item.gallery.document && (
              <Media id={item.gallery.document.id} key={item.gallery.document.id} data={item.gallery.document.data} />
            )
          ))}
        </div>
      )}
    </div>
  )
}

export const query = graphql`
  fragment ServiceSummaryFragment on PrismicPageBodyServiceSummary {
    slice_type
    primary {
      id
      serviceTitle: title {
        html
        text
      }
      description: description {
        html
      }
      serviceTitle2: title_2 {
        html
        text
      }
      description2: description_2 {
        html
      }
      buttons {
        document {
          ... PrismicButtonFragment
        }
      }
      invert_layout
    }
    items {
      gallery {
        ...PrismicMediaFragment
      }
    }
  }
`

ServiceSummary.propTypes = {
  body: PropTypes.object,
}

export default ServiceSummary
