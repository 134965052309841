import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import LangContext from '../../context/LangContext'
import './button.scss'

const Button = ({
  children, isButton, className, secondary, to, ...rest
}) => {
  const { langPrefix } = React.useContext(LangContext)
  if (isButton) {
    return (
      <button type='button' className={`hype-button ${className}`} {...rest}>
        {children}
      </button>)
  }
  return (
    <Link
      to={`${langPrefix}${to}`}
      className={`hype-button ${secondary && 'hype-button-secondary'} ${className}`}
      {...rest}
    >
      {children}
    </Link>)
}

Button.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string,
  isButton: PropTypes.bool,
  to: PropTypes.string,
}

export const query = graphql`
  fragment PrismicButtonFragment on PrismicButtons {
    data {
      button {
        label
        url
        style
      }
    }
  }
`

export default Button
