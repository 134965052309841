import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { RichText } from '../html'
import Slider from '../slider'
import Title from './Title'
import './TestimonialsSlider.scss'

const TestimonialsSlider = ({ body }) => {
  const { primary, items } = body
  return (
    <div className='testimonial-slider'>
      <div className='testimonials-header'>
        <Title title={primary.sliderTitle} centered />
        <RichText render={primary.subtitle.raw} />
      </div>
      <Slider controlsColor='secondary' slidesToShow={{ mobile: 1, tablet: 1, desktop: 1 }}>
        {items.map(({ testimonial }) => <RichText render={testimonial} />)}
      </Slider>
    </div>
  )
}


export const query = graphql`
  fragment TestimonialsSliderFragment on PrismicPageBodyTestimonialSlider {
    slice_type
    primary {
      sliderTitle: title {
        html
      }
      subtitle: subtitle {
        html
      }
    }
    items {
      testimonial {
        html
      }
    }
  }
`

TestimonialsSlider.propTypes = {
  body: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default TestimonialsSlider
