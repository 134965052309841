import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'

import PlayIcon from '../../../static/images/play-button.svg'
import './Video.scss'

const Video = ({
  embedURL, hostedURL, title, containerSize, image,
}) => {
  const [play, togglePlay] = React.useState(false)
  const playVideo = () => {
    togglePlay(!play)
  }
  return (
    <div className='video-container' style={{ paddingBottom: containerSize === 'full' ? '56.25%' : '63.8%' }}>
      {
        hostedURL && (
          <video loop controls className='video'><source src={hostedURL} type='video/mp4' /></video>
        )
      }
      {
        embedURL && (
          <div>
            {!play && (
              <div className='video-overlay'>
                <GatsbyImage fluid={image.fluid} alt={image.alt} />
                <button type='button' onClick={playVideo}>
                  <PlayIcon />
                </button>
              </div>
            )}
            <iframe
              className='video'
              src={`${embedURL}${play ? '?autoplay=1' : ''}`}
              title={title}
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              frameBorder='0'
              webkitallowfullscreen='true'
              mozallowfullscreen='true'
              allowFullScreen
            />
          </div>
        )
      }
    </div>
  )
}

Video.propTypes = {
  embedURL: PropTypes.string,
  hostedURL: PropTypes.string,
  title: PropTypes.string,
  containerSize: PropTypes.string.isRequired,
  image: PropTypes.object,
}

export default Video
